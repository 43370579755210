<template>
  <div class="contact-form" v-if="hasSubmitted">
    <div class="form-group">
      <label for="msg-name">{{ $t('First name') }}</label>
      <input
        type="text"
        id="msg-name"
        class="form-control"
        :placeholder="$t('What is your first name?')"
        v-model.trim="first_name.val"
        @blur="clearValidity('first_name')"/>
      <p class="error-state" v-if="!first_name.isValid">{{ $t('First name') }} {{ $t('is required') }}</p>
    </div>
    <div class="form-group">
      <label for="msg-last-name">{{ $t('Last name') }}</label>
      <input
        type="text"
        id="msg-last-name"
        class="form-control"
        :placeholder="$t('What is your last name?')"
        v-model.trim="last_name.val"
        @blur="clearValidity('last_name')"/>
      <p class="error-state" v-if="!last_name.isValid">{{ $t('Last name') }} {{ $t('is required') }}</p>
    </div>
    <div class="form-group">
      <label for=" msg-email">{{ $t('Email') }}</label>
      <input
        type="text"
        id="msg-email"
        class="form-control"
        :placeholder="$t('On what email adress can we reach you?')"
        v-model.trim="email.val"
        @blur="clearValidity('email')"/>
      <p class="error-state" v-if="!email.isValid">{{ $t('Email') }} {{ $t('is required') }}</p>
    </div>
    <div class="form-group">
      <label for=" msg-topic">{{ $t('Topic') }}</label>
      <select name="refer" v-model.trim="refer.val" @blur="clearValidity('refer')" required>
        <option value="" disabled selected hidden>{{ $t('Select your topic') }}</option>
        <option v-for="(topic, i) in topics" :key="i" :value="topic.id">
          {{ topic.title }}
        </option>
      </select>
      <p class="error-state" v-if="!refer.isValid">{{ $t('Field') }} {{ $t('is required') }}</p>
    </div>
    <div class="form-group">
      <label for="msg-message">{{ $t('Question') }}</label>
      <textarea
        rows="10"
        id="msg-question"
        :placeholder="$t('Ask us your question...')"
        class="form-control"
        v-model.trim="question.val"
        @blur="clearValidity('question')"></textarea>
      <p class="error-state" v-if="!question.isValid">{{ $t('Field') }} {{ $t('is required') }}</p>
    </div>
    <div class="form-group submit-wrapper">
      <p v-if="!formIsValid">{{ $t('Please fix the above errors and submit again.') }}</p>
      <button class="btn-default" @click="submitForm">
        <span>{{ $t('Send message') }}</span>
      </button>
    </div>
  </div>
  <div v-else class="contact-form--thanks">
    <img src="~@/assets/img/logos/mercedes-icon.svg" alt="Mercedes icon"/>
    <h6>{{ $t('Thank you for getting in touch!') }}</h6>
    <p v-html="$t('thank_you_message')"/>
  </div>
</template>

<script>
import axios from "@/helpers/axios";

export default {
  name: "ContactForm",
  props: [],
  data() {
    return {
      value1: true,

      hasSubmitted: true, // if submitted is true show thank you message

      // this are form inputs fields and values
      first_name: {
        val: "",
        isValid: true,
      },
      last_name: {
        val: "",
        isValid: true,
      },
      email: {
        val: "",
        isValid: true,
      },
      refer: {
        val: "",
        isValid: true,
      },
      question: {
        val: "",
        isValid: true,
      },

      formIsValid: true, // checks if all fields have a value
    };
  },
  computed: {
    topics(){
      return this.$store.state.cache.data[`topics${this.$i18n.locale}`]?.topics ?? [];
    }
  },
  methods: {
    loadTopics(){
      const key = `topics${this.$i18n.locale}`;
      const url = `${process.env.VUE_APP_API_URL}/api/topics/all?locale=${this.$i18n.locale}`;
      this.$store.commit('cacheRequest', {key, url});
    },
    clearValidity(input) {
      // removes the error message when the field is filled in again
      this[input].isValid = true;
    },

    validateForm() {
      // form is invalid when not all fields have a value
      this.formIsValid = true;
      if (this.first_name.val === "") {
        this.first_name.isValid = false;
        this.formIsValid = false;
      }
      if (this.last_name.val === "") {
        this.last_name.isValid = false;
        this.formIsValid = false;
      }
      if (this.email.val === "" || !this.email.val.includes("@")) {
        this.email.isValid = false;
        this.formIsValid = false;
      }
      if (this.refer.val === "") {
        this.refer.isValid = false;
        this.formIsValid = false;
      }
      if (this.question.val === "") {
        this.question.isValid = false;
        this.formIsValid = false;
      }
    },
    async submitForm() {
      this.validateForm();
      if (!this.formIsValid) {
        return;
      } else {
        this.hasSubmitted = false;
      }
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/contact/submitForm`, {
          locale: this.$i18n.locale,
          topic_id: this.refer.val,
          first_name: this.first_name.val,
          last_name: this.last_name.val,
          email: this.email.val,
          question: this.question.val,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  async created() {
    this.loadTopics();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadTopics();
    },
  },
};
</script>

<style lang="scss" scoped>
select:invalid {
  color: gray !important;
  option{
    color: white!important;
  }
}
</style>
